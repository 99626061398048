import Style from './AuthentificationInscription.module.css';
import { useState } from 'react';
import clsx from 'clsx';
import { reloadCurrentUser } from '_core/authentification/service/AuthService';
import { saveInscriptionEtapeCinq } from '_core/authentification/service/inscription/InscriptionService';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { wordingUtil } from '_common/service/WordingUtil';
import { useListeReferentiel } from '_core/authentification/service/inscription/InscriptionService';
import Loading from '_common/component/icon/Loading';

const AuthentificationInscriptionEtapeCinq = () => {
    const [errorServeur, setErrorServeur] = useState('');
    const [situationList, situationListLoaded] = useListeReferentiel('situation');

    const validationInscriptionSchema = Yup.object().shape({
        situation: Yup.array()
            .min(1, 'Le champ "Situation professionnelle" est obligatoire.')
            .of(Yup.string().required('Le champ "Situation professionnelle" est obligatoire.'))
            .required('Le champ "Situation professionnelle" est obligatoire.'),
        champLibreSituation: Yup.string().when('situation', ([situation], validationSchema) => {
            return situation.includes('autre')
                ? validationSchema.required('Le champ "Autre" est obligatoire.')
                : validationSchema;
        }),
    });

    return (
        <>
            <h2 className={clsx(Style.FormInscriptionEtapeTitleSecondary, Style.TitleSecondary)}>
                Quelle est ta situation professionnelle ?
            </h2>
            <span className={Style.Etape}>Etape 4/5</span>
            <span className={clsx('special-italic-text', Style.RequiredText)} aria-hidden="true">
                * champs obligatoires
            </span>
            {situationListLoaded ? (
                <Formik
                    initialValues={{
                        situation: [],
                    }}
                    validationSchema={validationInscriptionSchema}
                    onSubmit={async (values) => {
                        try {
                            await saveInscriptionEtapeCinq(values);
                            reloadCurrentUser();
                        } catch (err) {
                            setErrorServeur(wordingUtil.erreur);
                        }
                    }}
                >
                    {({ handleChange, touched, values, errors, isSubmitting }) => (
                        <Form className={Style.FormInscriptionEtapeContainer} noValidate>
                            {((Object.keys(errors).length !== 0 && Object.keys(touched).length !== 0) ||
                                errorServeur) && (
                                <div role="alert">
                                    <h3 className="special-italic-text red-error error-container-titre">
                                        Ce formulaire
                                        comprend {Object.keys(errors).length + (errorServeur ? 1 : 0)}{' '}
                                        erreur
                                        {Object.keys(errors).length + (errorServeur ? 1 : 0) > 1 ? 's' : ''} :
                                    </h3>
                                    <ul className="error-container">
                                        {errors.situation && touched.situation && (
                                            <li>
                                                <a
                                                    className={clsx('special-italic-text', 'red-error')}
                                                    href={`#${situationList[0].id}`}
                                                    id="situation_error"
                                                >
                                                    {errors.situation}
                                                </a>
                                            </li>
                                        )}
                                        {errors.champLibreSituation && touched.champLibreSituation && (
                                            <li>
                                                <a
                                                    className={clsx('special-italic-text', 'red-error')}
                                                    href="#champ-libre"
                                                    id="champLibreSituation_error"
                                                >
                                                    {errors.champLibreSituation}
                                                </a>
                                            </li>
                                        )}
                                        {errorServeur && (
                                            <li className={clsx('special-italic-text', 'red-error')}>{errorServeur}</li>
                                        )}
                                    </ul>
                                </div>
                            )}

                            <fieldset aria-describedby="situation_error situation_instruction">
                                <span className={clsx('special-italic-text')} id="situation_instruction">
                                    N.B. : plusieurs réponses possibles
                                </span>
                                <legend className={clsx(Style.Label, 'label-input', 'sr-only')}>
                                    Choisis une situation parmi les situations professionnelles suivantes :
                                </legend>
                                {situationListLoaded &&
                                    situationList.map((situation) => {
                                        const valuesFormSituation = values.situation.map((situation) =>
                                            situation === 'autre' ? situation : Number.parseInt(situation, 10),
                                        );
                                        return (
                                            <div key={situation.libelle}>
                                                <input
                                                    type="checkbox"
                                                    id={situation.id}
                                                    name="situation"
                                                    onChange={handleChange}
                                                    value={situation.id}
                                                    checked={valuesFormSituation.includes(situation.id)}
                                                    className="sr-only"
                                                />
                                                <label htmlFor={situation.id}>{situation.libelle}</label>
                                            </div>
                                        );
                                    })}
                                <div>
                                    <input
                                        type="checkbox"
                                        id="autre"
                                        name="situation"
                                        onChange={handleChange}
                                        value="autre"
                                        checked={values.situation.includes('autre')}
                                        className="sr-only"
                                    />
                                    <label htmlFor="autre">Autre : </label>
                                    <label
                                        htmlFor="champ-libre"
                                        className={clsx(Style.Label, 'label-input', 'sr-only')}
                                    >
                                        Précise <span aria-hidden="true">*</span>
                                    </label>
                                    <input
                                        id="champ-libre"
                                        type="text"
                                        className={
                                            errors.champLibreSituation && touched.champLibreSituation
                                                ? clsx('input-field', 'red-error-field', Style.Nom)
                                                : clsx('input-field', Style.Nom)
                                        }
                                        name="champLibreSituation"
                                        value={values.champLibreSituation}
                                        required
                                        aria-describedby="champLibreSituation_error"
                                        onChange={handleChange}
                                        placeholder="Précise"
                                        disabled={!values.situation.includes('autre')}
                                    />
                                    {errors.champLibreSituation && touched.champLibreSituation && (
                                        <span className={clsx('special-italic-text', 'red-error')}>
                                            {errors.champLibreSituation}
                                        </span>
                                    )}
                                </div>
                                {touched.situation && errors.situation && (
                                    <span className={clsx('special-italic-text', 'red-error')}>{errors.situation}</span>
                                )}
                            </fieldset>

                            <div className={Style.FormInscriptionEtapeButtonContainer}>
                                <button
                                    className={clsx(Style.ButtonConnexion, 'button-green', 'coloured-button', 'button')}
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Suivant
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            ) : (
                <Loading />
            )}
        </>
    );
};

export default AuthentificationInscriptionEtapeCinq;

import React from "react";
import PropTypes from "prop-types";

const Faq = ({ color = "#1D1D1F" }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-hidden="true">
      <path
        d="M14.0002 25.6673C20.4435 25.6673 25.6668 20.444 25.6668 14.0007C25.6668 7.55733 20.4435 2.33398 14.0002 2.33398C7.55684 2.33398 2.3335 7.55733 2.3335 14.0007C2.3335 20.444 7.55684 25.6673 14.0002 25.6673Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.605 10.5007C10.8793 9.72094 11.4207 9.06346 12.1333 8.64465C12.8459 8.22585 13.6837 8.07276 14.4984 8.2125C15.313 8.35223 16.0519 8.77578 16.5842 9.40811C17.1165 10.0405 17.4079 10.8408 17.4066 11.6673C17.4066 14.0007 13.9066 15.1673 13.9066 15.1673"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14 19.834H14.0117" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
Faq.propTypes = {
  color: PropTypes.string,
};
export default Faq;

import AuthentificationInscriptionEtapeDeux from '_core/authentification/component/inscription/AuthentificationInscriptionEtapeDeux';
import Style from './CompletionProfilModal.module.css';
import { useEffect, useRef, useState } from 'react';
import AuthentificationInscriptionEtapeTrois from '_core/authentification/component/inscription/AuthentificationInscriptionEtapeTrois';
import AuthentificationInscriptionEtapeQuatre from '_core/authentification/component/inscription/AuthentificationInscriptionEtapeQuatre';
import AuthentificationInscriptionEtapeCinq from '_core/authentification/component/inscription/AuthentificationInscriptionEtapeCinq';
import AuthentificationInscriptionEtapeSix from '_core/authentification/component/inscription/AuthentificationInscriptionEtapeSix';
import { getAuth, hasRole } from '_core/authentification/service/AuthService';

const CompletionProfilModal = () => {
    let auth = getAuth();
    const user = auth.user;
    const userEnCoursInscription =
        user.etapeInscription &&
        hasRole(['ROLE_ADHERENTE_EN_COURS_INSCRIPTION'], user) &&
        user.etapeInscription !== 'termine';
    const ref = useRef();
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        setOpenModal(userEnCoursInscription);
        if (openModal) {
            ref.current?.showModal();
        } else {
            ref.current?.close();
        }
    }, [openModal, userEnCoursInscription]);

    return (
        <dialog className={Style.CompletionProfilModal} ref={ref}>
            {user.etapeInscription && user.etapeInscription === 'etape_deux' && (
                <AuthentificationInscriptionEtapeDeux />
            )}
            {user.etapeInscription && user.etapeInscription === 'etape_trois' && (
                <AuthentificationInscriptionEtapeTrois />
            )}
            {user.etapeInscription && user.etapeInscription === 'etape_quatre' && (
                <AuthentificationInscriptionEtapeQuatre />
            )}
            {user.etapeInscription && user.etapeInscription === 'etape_cinq' && (
                <AuthentificationInscriptionEtapeCinq />
            )}
            {user.etapeInscription && user.etapeInscription === 'etape_six' && <AuthentificationInscriptionEtapeSix />}

            <p>
                👉 Ces questions nous permettent de mieux connaitre le profil de nos membres et d’apporter des réponses
                adaptées à vos besoins.
            </p>
        </dialog>
    );
};

export default CompletionProfilModal;

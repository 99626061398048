import Style from './AuthentificationInscription.module.css';
import { useState } from 'react';
import clsx from 'clsx';
import { reloadCurrentUser } from '_core/authentification/service/AuthService';
import { saveInscriptionEtapeQuatre } from '_core/authentification/service/inscription/InscriptionService';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { wordingUtil } from '_common/service/WordingUtil';
import { useListeReferentiel } from '_core/authentification/service/inscription/InscriptionService';
import Loading from '_common/component/icon/Loading';

const AuthentificationInscriptionEtapeQuatre = () => {
    const [errorServeur, setErrorServeur] = useState('');
    const [secteurActiviteList, secteurActiviteLoaded] = useListeReferentiel('activite');
    const [ambitionList, ambitionLoaded] = useListeReferentiel('ambition');

    const validationInscriptionSchema = Yup.object().shape({
        ambition: Yup.string().required('Le champ "Aujourd\'hui" est obligatoire.'),
        champLibreAmbition: Yup.string().when('ambition', ([ambition], validationSchema) => {
            return ambition === 'autre'
                ? validationSchema.required('Le champ "Autre" est obligatoire.')
                : validationSchema;
        }),
        secteurActiviteId: Yup.string().required('Le champ "Secteur d\'activité" est obligatoire.'),
    });

    return (
        <>
            <h2 className={clsx(Style.FormInscriptionEtapeTitleSecondary, Style.TitleSecondary)}>
                Parlons de ce que tu fais.
            </h2>
            <span className={Style.Etape}>Etape 3/5</span>
            <span className={clsx('special-italic-text', Style.RequiredText)} aria-hidden='true'>
                * champs obligatoires
            </span>
            {secteurActiviteLoaded && ambitionLoaded ? (
                <Formik
                    initialValues={{
                        ambition: '',
                        secteurActiviteId: '',
                    }}
                    validationSchema={validationInscriptionSchema}
                    onSubmit={async (values) => {
                        try {
                            await saveInscriptionEtapeQuatre(values);
                            reloadCurrentUser();
                        } catch (err) {
                            setErrorServeur(wordingUtil.erreur);
                        }
                    }}
                >
                    {({ handleChange, touched, values, errors, isSubmitting }) => (
                        <Form className={Style.FormInscriptionEtapeContainer} noValidate>
                            {((Object.keys(errors).length !== 0 && Object.keys(touched).length !== 0) ||
                                errorServeur) && (
                                <div role='alert'>
                                    <h3 className='special-italic-text red-error error-container-titre'>
                                        Ce formulaire comprend {Object.keys(errors).length + (errorServeur ? 1 : 0)}{' '}
                                        erreur
                                        {Object.keys(errors).length + (errorServeur ? 1 : 0) > 1 ? 's' : ''} :
                                    </h3>
                                    <ul className='error-container'>
                                        {errors.ambition && touched.ambition && (
                                            <li>
                                                <a
                                                    className={clsx('special-italic-text', 'red-error')}
                                                    href={`#${ambitionList[0].id}`}
                                                    id='ambition_error'
                                                >
                                                    {errors.ambition}
                                                </a>
                                            </li>
                                        )}
                                        {errors.champLibreAmbition && touched.champLibreAmbition && (
                                            <li>
                                                <a
                                                    className={clsx('special-italic-text', 'red-error')}
                                                    href='#champ-libre'
                                                    id='champLibreAmbition_error'
                                                >
                                                    {errors.champLibreAmbition}
                                                </a>
                                            </li>
                                        )}
                                        {errors.secteurActiviteId && touched.secteurActiviteId && (
                                            <li>
                                                <a
                                                    className={clsx('special-italic-text', 'red-error')}
                                                    href='#secteurActivite'
                                                    id='secteur_error'
                                                >
                                                    {errors.secteurActiviteId}
                                                </a>
                                            </li>
                                        )}
                                        {errorServeur && (
                                            <li className={clsx('special-italic-text', 'red-error')}>{errorServeur}</li>
                                        )}
                                    </ul>
                                </div>
                            )}

                            <fieldset aria-describedby='ambition_error'>
                                <legend className={clsx(Style.Label, 'label-input', Style.LabelEtape)}>
                                    Aujourd&rsquo;hui...
                                </legend>
                                {ambitionLoaded &&
                                    ambitionList.map((ambition) => {
                                        return (
                                            <div key={ambition.libelle}>
                                                <input
                                                    type='radio'
                                                    id={ambition.id}
                                                    name='ambition'
                                                    onChange={handleChange}
                                                    value={ambition.id}
                                                    checked={Number(values.ambition) === ambition.id}
                                                    className='sr-only'
                                                />
                                                <label htmlFor={ambition.id}>{ambition.libelle}</label>
                                            </div>
                                        );
                                    })}
                                <div>
                                    <input
                                        type='radio'
                                        id='autre'
                                        name='ambition'
                                        onChange={handleChange}
                                        value='autre'
                                        checked={values.ambition === 'autre'}
                                        className='sr-only'
                                    />
                                    <label htmlFor='autre'>Autre : </label>
                                    <label
                                        htmlFor='champ-libre'
                                        className={clsx(Style.Label, 'label-input', 'sr-only')}
                                    >
                                        Précise <span aria-hidden='true'>*</span>
                                    </label>
                                    <input
                                        id='champ-libre'
                                        type='text'
                                        className={
                                            errors.champLibreAmbition && touched.champLibreAmbition
                                                ? clsx('input-field', 'red-error-field', Style.Nom)
                                                : clsx('input-field', Style.Nom)
                                        }
                                        name='champLibreAmbition'
                                        value={values.champLibreAmbition}
                                        required
                                        aria-describedby='champLibreAmbition_error'
                                        onChange={handleChange}
                                        placeholder='Précise'
                                        disabled={values.ambition !== 'autre'}
                                    />
                                    {errors.champLibreAmbition && touched.champLibreAmbition && (
                                        <span className={clsx('special-italic-text', 'red-error')}>
                                            {errors.champLibreAmbition}
                                        </span>
                                    )}
                                </div>
                                {touched.ambition && errors.ambition && (
                                    <span className={clsx('special-italic-text', 'red-error')}>{errors.ambition}</span>
                                )}
                            </fieldset>

                            <label
                                htmlFor='secteurActivite'
                                className={clsx(Style.Label, 'label-input', Style.LabelEtape)}
                            >
                                Dans quel secteur d&rsquo;activité <span aria-hidden='true'>*</span> ?
                            </label>
                            <div className={clsx(Style.CustomSelect, 'custom-select')}>
                                <select
                                    id='secteurActivite'
                                    onChange={handleChange}
                                    name='secteurActiviteId'
                                    value={values.secteurActiviteId}
                                    className={
                                        touched.themeId && errors.themeId
                                            ? clsx('input-field', 'red-error-field')
                                            : 'input-field'
                                    }
                                    required
                                    aria-describedby='secteur_error'
                                >
                                    <option value='' disabled>
                                        Sélectionne dans la liste
                                    </option>

                                    {secteurActiviteLoaded &&
                                        secteurActiviteList.map((secteurActivite) => {
                                            return (
                                                <option key={secteurActivite.libelle} value={secteurActivite.id}>
                                                    {secteurActivite.libelle}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                            {touched.secteurActiviteId && errors.secteurActiviteId && (
                                <span className={clsx('special-italic-text', 'red-error')}>
                                    {errors.secteurActiviteId}
                                </span>
                            )}

                            <div className={Style.FormInscriptionEtapeButtonContainer}>
                                <button
                                    className={clsx(Style.ButtonConnexion, 'button-green', 'coloured-button', 'button')}
                                    type='submit'
                                    disabled={isSubmitting}
                                >
                                    Suivant
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            ) : (
                <Loading />
            )}
        </>
    );
};

export default AuthentificationInscriptionEtapeQuatre;

import Style from './AuthentificationInscription.module.css';
import { useState, useCallback } from 'react';
import clsx from 'clsx';
import { getAuth, reloadCurrentUser } from '_core/authentification/service/AuthService';
import { saveInscriptionEtapeTrois } from '_core/authentification/service/inscription/InscriptionService';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { wordingUtil } from '_common/service/WordingUtil';
import { getVilles } from '_core/authentification/service/AuthServiceApi';
import { debounce } from '_common/service/FunUtil';

const AuthentificationInscriptionEtapeTrois = () => {
    const [errorServeur, setErrorServeur] = useState('');
    const [messageAlerteVille, setMessageAlerteVille] = useState('');
    let auth = getAuth();
    const user = auth.user;

    const validationInscriptionSchema = Yup.object().shape({
        codePostal: Yup.string()
            .matches(/[0-9]{5}/, 'Le code postal doit contenir 5 chiffres.')
            .required('Le champ "Code Postal" est obligatoire.'),
        ville: Yup.string().when('codePostal', ([codePostal], validationInscriptionSchema) => {
            return codePostal && codePostal.trim().length === 5
                ? validationInscriptionSchema.required(
                      'Le champ "Commune" est obligatoire. Veuillez sélectionner une commune dans la liste.'
                  )
                : validationInscriptionSchema.required(
                      'Le champ "Commune" est obligatoire. Veuillez renseigner un Code Postal valide pour pouvoir sélectionner une commune dans la liste.'
                  );
        }),
    });

    const [visibleOptionsArray, setVisibleOptionsArray] = useState([]);

    const loadVilles = useCallback(
        debounce(500, (code) => {
            const load = async () => {
                const villes = await getVilles(code);
                setVisibleOptionsArray(villes);
            };
            load();
        }),
        []
    );

    return (
        <>
            <h2 className={clsx(Style.FormInscriptionEtapeTitleSecondary, Style.TitleSecondary)}>
                <span>
                    Merci <span className={Style.ValidationPrenom}>{user.adherente.prenom}</span> !
                </span>
                <br />
                <span>Dis nous à présent d&rsquo;où tu viens.</span>
            </h2>
            <span className={Style.Etape}>Etape 2/5</span>
            <span className={clsx('special-italic-text', Style.RequiredText)} aria-hidden='true'>
                * champs obligatoires
            </span>
            <Formik
                initialValues={{
                    codePostal: '',
                    ville: '',
                }}
                validationSchema={validationInscriptionSchema}
                onSubmit={async (values) => {
                    try {
                        await saveInscriptionEtapeTrois(values);
                        reloadCurrentUser();
                    } catch (err) {
                        setErrorServeur(wordingUtil.erreur);
                    }
                }}
            >
                {({ handleChange, touched, values, errors, setFieldValue, isSubmitting }) => (
                    <Form className={Style.FormInscriptionEtapeContainer} noValidate>
                        <div id='zone-alerte' role='alert' aria-live='assertive' className='sr-only'>
                            {messageAlerteVille}
                        </div>
                        {((Object.keys(errors).length !== 0 && Object.keys(touched).length !== 0) || errorServeur) && (
                            <div role='alert'>
                                <h3 className='special-italic-text red-error error-container-titre'>
                                    Ce formulaire comprend {Object.keys(errors).length + (errorServeur ? 1 : 0)} erreur
                                    {Object.keys(errors).length + (errorServeur ? 1 : 0) > 1 ? 's' : ''} :
                                </h3>
                                <ul className='error-container'>
                                    {errors.codePostal && touched.codePostal && (
                                        <li>
                                            <a
                                                className={clsx('special-italic-text', 'red-error')}
                                                href='#a11y-code-postal'
                                                id='code_postal_error'
                                            >
                                                {errors.codePostal}
                                            </a>
                                        </li>
                                    )}
                                    {errors.ville && touched.ville && (
                                        <li>
                                            <a
                                                className={clsx('special-italic-text', 'red-error')}
                                                href='#a11y-ville'
                                                id='ville_error'
                                            >
                                                {errors.ville}
                                            </a>
                                        </li>
                                    )}
                                    {errorServeur && (
                                        <li className={clsx('special-italic-text', 'red-error')}>{errorServeur}</li>
                                    )}
                                </ul>
                            </div>
                        )}

                        <label
                            htmlFor='a11y-code-postal'
                            className={clsx(Style.Label, 'label-input')}
                            aria-label='Vous devez remplir le code postal pour pouvoir remplir la ville dans le prochain champ'
                        >
                            Code Postal <span aria-hidden='true'>*</span>
                        </label>
                        <input
                            id='a11y-code-postal'
                            name='codePostal'
                            autoComplete='none'
                            type='text'
                            pattern='[0-9]{5}'
                            title='Le code postal doit contenir 5 chiffres.'
                            className={
                                errors.codePostal && touched.codePostal
                                    ? clsx('input-field', 'red-error-field', Style.DateNaissance)
                                    : clsx(Style.DateNaissance, 'input-field')
                            }
                            value={values.codePostal}
                            required
                            aria-describedby='code_postal_error'
                            onChange={(e) => {
                                handleChange(e);
                                if (e.target.value.trim() && e.target.value.length === 5) {
                                    loadVilles(e.target.value.trim());
                                    setFieldValue('codePostal', e.target.value.trim());
                                    setMessageAlerteVille(wordingUtil.informationSelectionVille);
                                }
                            }}
                        />
                        {errors.codePostal && touched.codePostal && (
                            <span className={clsx('special-italic-text', 'red-error')}>{errors.codePostal}</span>
                        )}
                        <label
                            htmlFor='a11y-ville'
                            className={clsx(Style.Label, 'label-input')}
                            aria-label='Vous devez remplir le code postal pour pouvoir remplir ce champ'
                        >
                            Commune <span aria-hidden='true'>*</span>
                        </label>
                        <div className={clsx(Style.CustomSelect, 'custom-select')}>
                            <select
                                id='a11y-ville'
                                className={
                                    errors.ville && touched.ville
                                        ? clsx('input-field', 'red-error-field')
                                        : 'input-field'
                                }
                                name='ville'
                                value={values.ville}
                                required
                                aria-describedby='ville_error'
                                onChange={handleChange}
                            >
                                <option value='' disabled>
                                    {' '}
                                    Sélectionne ta commune dans la liste
                                </option>
                                {visibleOptionsArray.map((c) => {
                                    return (
                                        <option key={c.id} value={c.id}>
                                            {c.nom}
                                        </option>
                                    );
                                })}
                            </select>
                            {errors.ville && touched.ville && (
                                <span className={clsx('special-italic-text', 'red-error')}>{errors.ville}</span>
                            )}
                        </div>
                        <div className={Style.FormInscriptionEtapeButtonContainer}>
                            <button
                                className={clsx(Style.ButtonConnexion, 'button-green', 'coloured-button', 'button')}
                                type='submit'
                                disabled={isSubmitting}
                            >
                                Suivant
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AuthentificationInscriptionEtapeTrois;

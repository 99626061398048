import Style from './AuthentificationInscription.module.css';
import { useState } from 'react';
import clsx from 'clsx';
import { getAuth, reloadCurrentUser } from '_core/authentification/service/AuthService';
import { saveInscriptionEtapeDeux } from '_core/authentification/service/inscription/InscriptionService';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { wordingUtil } from '_common/service/WordingUtil';

const AuthentificationInscriptionEtapeDeux = () => {
    const [errorServeur, setErrorServeur] = useState('');
    let auth = getAuth();
    const user = auth.user;

    const containsRepetitiveChars = (string) => /((.)\2{1})\2+/.test(string);
    const validationInscriptionSchema = Yup.object().shape({
        nom: Yup.string()
            .min(2, 'Le nom doit comporter au minimum 2 caractères.')
            .max(45, 'Le nom doit comporter au maximum 45 caractères.')
            .required('Le champ "Nom" est obligatoire.')
            .matches(/[aeiouyAEIOUY]/, 'Le nom doit comporter au moins une voyelle.')
            .matches(/^([^0-9]*)$/, 'Le nom ne doit pas comporter de chiffres.')
            .matches(
                /^(((?!(firstname|lastname|unknown|first_name|last_name|anonyme|user|admin|name|nom|prénom|test)).)*$)/,
                'Le nom ne doit pas être : "firstname", "lastname", "unknown", "first_name", "last_name", "anonyme", “user", "admin", "name", “nom", "prénom", "test”'
            )
            .test(
                'caractères répétitifs dans le nom',
                'Le nom doit comporter 2 caractères identiques à la suite au maximum.',
                (value) => !containsRepetitiveChars(value)
            )
            .matches(
                /^[a-zA-Z-'\sàâäéèêëùûüçÀÂÈÉÊËÙÛÜÇ]*$/,
                'Le nom doit comporter uniquement ces caractères spéciaux : les lettres "e", "a" et "u" accentuées, "‘", "-", "ç"'
            )
            .notOneOf([user.adherente.prenom, null], 'Le prénom et le nom ne doivent pas être identiques.'),
        dateNaissance: Yup.date('La date de naissance est invalide.')
            .required('Le champ "Date de naissance" est obligatoire.')
            .test(
                'âge',
                'Vous devez avoir plus de 18 ans pour pouvoir vous inscrire.',
                (value) => value.getFullYear() <= new Date().getFullYear() - 18
            ),
    });

    return (
        <>
            <h2 className={clsx(Style.FormInscriptionEtapeTitleSecondary, Style.TitleSecondary)}>
                <span className={Style.SubTitleSecondary}>
                    Nous allons à présent t&rsquo;accompagner dans la création de ton profil.
                </span>
                <br />
                Dis nous en plus sur toi !
            </h2>
            <span className={Style.Etape}>Etape 1/5</span>
            <span className={clsx('special-italic-text', Style.RequiredText)} aria-hidden='true'>
                * champs obligatoires
            </span>
            <Formik
                initialValues={{
                    nom: '',
                    dateNaissance: '',
                }}
                validationSchema={validationInscriptionSchema}
                onSubmit={async (values) => {
                    try {
                        await saveInscriptionEtapeDeux(values);
                        reloadCurrentUser();
                    } catch (err) {
                        setErrorServeur(wordingUtil.erreur);
                    }
                }}
            >
                {({ handleChange, touched, values, errors, isSubmitting }) => (
                    <Form className={Style.FormInscriptionEtapeContainer} noValidate>
                        {((Object.keys(errors).length !== 0 && Object.keys(touched).length !== 0) || errorServeur) && (
                            <div role='alert'>
                                <h3 className='special-italic-text red-error error-container-titre'>
                                    Ce formulaire comprend {Object.keys(errors).length + (errorServeur ? 1 : 0)} erreur
                                    {Object.keys(errors).length + (errorServeur ? 1 : 0) > 1 ? 's' : ''} :
                                </h3>
                                <ul className='error-container'>
                                    {errors.nom && touched.nom && (
                                        <li>
                                            <a
                                                className={clsx('special-italic-text', 'red-error')}
                                                href='#a11y-nom'
                                                id='nom_error'
                                            >
                                                {errors.nom}
                                            </a>
                                        </li>
                                    )}
                                    {errors.dateNaissance && touched.dateNaissance && (
                                        <li>
                                            <a
                                                className={clsx('special-italic-text', 'red-error')}
                                                href='#date-naissance'
                                                id='date_error'
                                            >
                                                {errors.dateNaissance}
                                            </a>
                                        </li>
                                    )}
                                    {errorServeur && (
                                        <li className={clsx('special-italic-text', 'red-error')}>{errorServeur}</li>
                                    )}
                                </ul>
                            </div>
                        )}

                        <label htmlFor='a11y-nom' className={clsx(Style.Label, 'label-input')}>
                            Nom <span aria-hidden='true'>*</span>
                        </label>
                        <input
                            id='a11y-nom'
                            autoComplete='family-name'
                            type='text'
                            maxLength='45'
                            title='Le nom doit contenir 45 caractères maximum.'
                            className={
                                errors.nom && touched.nom
                                    ? clsx('input-field', 'red-error-field', Style.Nom)
                                    : clsx('input-field', Style.Nom)
                            }
                            name='nom'
                            value={values.nom}
                            required
                            aria-describedby='nom_error'
                            onChange={handleChange}
                        />
                        {errors.nom && touched.nom && (
                            <span className={clsx('special-italic-text', 'red-error')}>{errors.nom}</span>
                        )}
                        <label htmlFor='date-naissance' className={clsx(Style.Label, 'label-input')}>
                            Date de naissance <span aria-hidden='true'>*</span>
                        </label>
                        <input
                            id='date-naissance'
                            type='date'
                            name='dateNaissance'
                            required
                            onChange={handleChange}
                            value={values.dateNaissance}
                            className={
                                touched.dateNaissance && errors.dateNaissance
                                    ? clsx(Style.DateNaissance, 'input-field', 'red-error-field')
                                    : clsx('input-field', Style.DateNaissance)
                            }
                            aria-describedby='date_error'
                        />
                        {errors.dateNaissance && touched.dateNaissance && (
                            <span className={clsx('special-italic-text', 'red-error')}>{errors.dateNaissance}</span>
                        )}

                        <div className={Style.FormInscriptionEtapeButtonContainer}>
                            <button
                                className={clsx(Style.ButtonConnexion, 'button-green', 'coloured-button', 'button')}
                                type='submit'
                                disabled={isSubmitting}
                            >
                                Suivant
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AuthentificationInscriptionEtapeDeux;

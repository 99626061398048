import { post } from '_common/service/ApiService';
import { useEffect, useState } from 'react';
import { getListeReferentiel } from './InscriptionServiceApi';

let routeInscription = process.env.REACT_APP_API_URL + '/api/inscription/etape_un';
export const saveInscription = async (valuesForm) => {
    const formData = new FormData();
    formData.append('prenom', valuesForm.prenom);
    formData.append('email', valuesForm.email);
    formData.append('motDePasse', valuesForm.password);
    return await post(routeInscription, {
        body: formData,
    });
};

let routeInscriptionEtapeDeux = process.env.REACT_APP_API_URL + '/api/inscription/etape_deux';
export const saveInscriptionEtapeDeux = async (valuesForm) => {
    const formData = new FormData();
    formData.append('nom', valuesForm.nom);
    formData.append('dateDeNaissance', valuesForm.dateNaissance);
    return await post(routeInscriptionEtapeDeux, {
        body: formData,
    });
};

let routeInscriptionEtapeTrois = process.env.REACT_APP_API_URL + '/api/inscription/etape_trois';
export const saveInscriptionEtapeTrois = async (valuesForm) => {
    const formData = new FormData();
    formData.append('villeId', Number(valuesForm.ville));
    return await post(routeInscriptionEtapeTrois, {
        body: formData,
    });
};

let routeInscriptionEtapeQuatre = process.env.REACT_APP_API_URL + '/api/inscription/etape_quatre';
export const saveInscriptionEtapeQuatre = async (valuesForm) => {
    const formData = new FormData();
    valuesForm.ambition !== 'autre' && formData.append('ambition', Number(valuesForm.ambition));
    formData.append('secteurActivite', Number(valuesForm.secteurActiviteId));
    valuesForm.champLibreAmbition &&
        valuesForm.ambition === 'autre' &&
        formData.append('nouvelleAmbition', valuesForm.champLibreAmbition);
    return await post(routeInscriptionEtapeQuatre, {
        body: formData,
    });
};

let routeInscriptionEtapeCinq = process.env.REACT_APP_API_URL + '/api/inscription/etape_cinq';
export const saveInscriptionEtapeCinq = async (valuesForm) => {
    return await post(routeInscriptionEtapeCinq, {
        body: JSON.stringify(
            {
                situations: valuesForm.situation.filter(
                    (situation) => situation !== 'autre'
                ).map(
                    (situation) => Number(situation)
                ),
                nouvelleSituation: valuesForm.situation.includes('autre') ? valuesForm.champLibreSituation : null,
            }
        ),
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

let routeInscriptionEtapeSix = process.env.REACT_APP_API_URL + '/api/inscription/etape_six';
export const saveInscriptionEtapeSix = async (valuesForm) => {
    return await post(routeInscriptionEtapeSix, {
        body: JSON.stringify(
            {
                attentes: valuesForm.attente.filter(
                    (attente) => attente !== 'autre'
                ).map(
                    (attente) => Number(attente)
                ),
                nouvelleAttente: valuesForm.attente.includes('autre') ? valuesForm.champLibreAttente : null,
            }
        ),
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const useListeReferentiel = (categorie) => {
    const [referentielList, setReferentielList] = useState([]);
    const [referentielLoaded, setReferentielLoaded] = useState(false);

    useEffect(() => {
        const loadReferentielList = async () => {
            const referentielList = await getListeReferentiel(categorie);
            setReferentielList(referentielList);
            setReferentielLoaded(true);
            window.scrollTo(0, 0);
        };
        loadReferentielList();
    }, [categorie]);
    return [referentielList, referentielLoaded];
};

import PropTypes from "prop-types";

const Loading = ({ size = 100 }) => {
  return (<svg
    style={{ margin: "auto", display: "block", shapeRendering: "auto" }} width={size + "px"} height={size + "px"}
    viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" role="img" aria-label="chargement en cours">
    <g transform="rotate(0 50 50)">
      <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#00aaa5">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="10s" begin="-9.166666666666668s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(30 50 50)">
      <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#00aaa5">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="10s" begin="-8.333333333333334s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(60 50 50)">
      <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#00aaa5">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="10s" begin="-7.5s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(90 50 50)">
      <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#00aaa5">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="10s" begin="-6.666666666666667s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(120 50 50)">
      <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#00aaa5">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="10s" begin="-5.833333333333334s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(150 50 50)">
      <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#00aaa5">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="10s" begin="-5s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(180 50 50)">
      <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#00aaa5">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="10s" begin="-4.166666666666667s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(210 50 50)">
      <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#00aaa5">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="10s" begin="-3.3333333333333335s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(240 50 50)">
      <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#00aaa5">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="10s" begin="-2.5s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(270 50 50)">
      <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#00aaa5">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="10s" begin="-1.6666666666666667s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(300 50 50)">
      <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#00aaa5">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="10s" begin="-0.8333333333333334s" repeatCount="indefinite"></animate>
      </rect>
    </g><g transform="rotate(330 50 50)">
      <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#00aaa5">
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="10s" begin="0s" repeatCount="indefinite"></animate>
      </rect>
    </g>
  </svg>);
}
Loading.propTypes = {
  size: PropTypes.number,
};
export default Loading;
import Style from './AuthentificationInscription.module.css';
import { useState } from 'react';
import clsx from 'clsx';
import { reloadCurrentUser } from '_core/authentification/service/AuthService';
import { saveInscriptionEtapeSix } from '_core/authentification/service/inscription/InscriptionService';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { wordingUtil } from '_common/service/WordingUtil';
import { useListeReferentiel } from '_core/authentification/service/inscription/InscriptionService';
import Loading from '_common/component/icon/Loading';

const AuthentificationInscriptionEtapeSix = () => {
    const [errorServeur, setErrorServeur] = useState('');
    const [attenteList, attenteListLoaded] = useListeReferentiel('attente');

    const validationInscriptionSchema = Yup.object().shape({
        attente: Yup.array()
            .min(1, 'Le champ "Attentes du réseau" est obligatoire.')
            .of(Yup.string().required('Le champ "Attentes du réseau" est obligatoire.'))
            .required('Le champ "Attentes du réseau" est obligatoire.'),
        champLibreAttente: Yup.string().when('attente', ([attente], validationSchema) => {
            return attente.includes('autre')
                ? validationSchema.required('Le champ "Autre" est obligatoire.')
                : validationSchema;
        }),
    });

    return (
        <>
            <h2 className={clsx(Style.FormInscriptionEtapeTitleSecondary, Style.TitleSecondary)}>
                Pour finir, quelles sont tes attentes en rejoignant Femmes des Territoires ?
            </h2>
            <span className={Style.Etape}>Etape 5/5</span>
            <span className={clsx('special-italic-text', Style.RequiredText)} aria-hidden='true'>
                * champ obligatoire
            </span>
            {attenteListLoaded ? (
                <Formik
                    initialValues={{
                        attente: [],
                    }}
                    validationSchema={validationInscriptionSchema}
                    onSubmit={async (values) => {
                        try {
                            await saveInscriptionEtapeSix(values);
                            reloadCurrentUser();
                        } catch (err) {
                            console.error(err);
                            setErrorServeur(wordingUtil.erreur);
                        }
                    }}
                >
                    {({ handleChange, touched, values, errors, isSubmitting }) => (
                        <Form className={Style.FormInscriptionEtapeContainer} noValidate>
                            {((Object.keys(errors).length !== 0 && Object.keys(touched).length !== 0) ||
                                errorServeur) && (
                                <div role='alert'>
                                    <h3 className='special-italic-text red-error error-container-titre'>
                                        Ce formulaire comprend {Object.keys(errors).length + (errorServeur ? 1 : 0)}{' '}
                                        erreur
                                        {Object.keys(errors).length + (errorServeur ? 1 : 0) > 1 ? 's' : ''} :
                                    </h3>
                                    <ul className='error-container'>
                                        {errors.attente && touched.attente && (
                                            <li>
                                                <a
                                                    className={clsx('special-italic-text', 'red-error')}
                                                    href={`#${attenteList[0].id}`}
                                                    id='attente_error'
                                                >
                                                    {errors.attente}
                                                </a>
                                            </li>
                                        )}
                                        {errors.champLibreAttente && touched.champLibreAttente && (
                                            <li>
                                                <a
                                                    className={clsx('special-italic-text', 'red-error')}
                                                    href='#champ-libre'
                                                    id='champLibreAttente_error'
                                                >
                                                    {errors.champLibreAttente}
                                                </a>
                                            </li>
                                        )}
                                        {errorServeur && (
                                            <li className={clsx('special-italic-text', 'red-error')}>{errorServeur}</li>
                                        )}
                                    </ul>
                                </div>
                            )}
                            <fieldset aria-describedby='attente_error attente_instruction'>
                                <span className={clsx('special-italic-text')} id='attente_instruction'>
                                    N.B. : plusieurs réponses possibles
                                </span>
                                <legend className={clsx(Style.Label, 'label-input', 'sr-only')}>
                                    Choisis parmi les réponses ci-dessous :
                                </legend>
                                {attenteListLoaded &&
                                    attenteList.map((attente) => {
                                        const valuesFormAttente = values.attente.map((attente) =>
                                            attente === 'autre' ? attente : Number.parseInt(attente, 10)
                                        );
                                        return (
                                            <div key={attente.libelle}>
                                                <input
                                                    type='checkbox'
                                                    id={attente.id}
                                                    name='attente'
                                                    onChange={handleChange}
                                                    value={attente.id}
                                                    checked={valuesFormAttente.includes(attente.id)}
                                                    className='sr-only'
                                                />
                                                <label htmlFor={attente.id}>{attente.libelle}</label>
                                            </div>
                                        );
                                    })}
                                <div>
                                    <input
                                        type='checkbox'
                                        id='autre'
                                        name='attente'
                                        onChange={handleChange}
                                        value='autre'
                                        checked={values.attente.includes('autre')}
                                        className='sr-only'
                                    />
                                    <label htmlFor='autre'>Autre : </label>
                                    <label
                                        htmlFor='champ-libre'
                                        className={clsx(Style.Label, 'label-input', 'sr-only')}
                                    >
                                        Précise <span aria-hidden='true'>*</span>
                                    </label>
                                    <input
                                        id='champ-libre'
                                        type='text'
                                        className={
                                            errors.champLibreAttente && touched.champLibreAttente
                                                ? clsx('input-field', 'red-error-field', Style.Nom)
                                                : clsx('input-field', Style.Nom)
                                        }
                                        name='champLibreAttente'
                                        value={values.champLibreAttente}
                                        required
                                        aria-describedby='champLibreAttente_error'
                                        onChange={handleChange}
                                        placeholder='Précise'
                                        disabled={!values.attente.includes('autre')}
                                    />
                                    {errors.champLibreAttente && touched.champLibreAttente && (
                                        <span className={clsx('special-italic-text', 'red-error')}>
                                            {errors.champLibreAttente}
                                        </span>
                                    )}
                                </div>
                                {touched.attente && errors.attente && (
                                    <span className={clsx('special-italic-text', 'red-error')}>{errors.attente}</span>
                                )}
                            </fieldset>

                            <div className={Style.FormInscriptionEtapeButtonContainer}>
                                <button
                                    className={clsx(Style.ButtonConnexion, 'button-green', 'coloured-button', 'button')}
                                    type='submit'
                                    disabled={isSubmitting}
                                >
                                    Accéder à la plateforme
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            ) : (
                <Loading />
            )}
        </>
    );
};

export default AuthentificationInscriptionEtapeSix;

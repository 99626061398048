import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import LogoFDT from '_common/component/icon/logoFDT.svg';
import Style from './AppHeader.module.css';
import vague from '_common/component/icon/Header.png';
import vagueMobile from '_common/component/icon/vagueMobile.png';
import messagerie from '_common/component/icon/messagerie.svg';
import notification from '_common/component/icon/bell.svg';
import burger from '_common/component/icon/burger.svg';
import closeIcon from '_common/component/icon/close.svg';
import fleche from '_common/component/icon/flechesBas.svg';
import { useOnClickOutside } from '_common/hook/useOnClickOutside';
import avatar from '_common/component/icon/avatarMembreDefault.svg';
import { addMessagerieListener, addNotificationListener } from './service/MessagerieAndNotificationRedDotService';
import {
    resolvePath,
    ROUTE_HOME,
    ROUTE_MESSAGERIE,
    ROUTE_NOTIFICATIONS,
    ROUTE_PARAMETRES,
    ROUTE_PROFIL,
    ROUTE_PROFIL_EDIT,
    ROUTE_AGENDA,
    ROUTE_MEMBRES,
    ROUTE_FIN_ADHESION,
} from '_core/router/routes';
import AppHeaderMenuBurgerMobile from '_core/app/header-menu-burger-mobile/AppHeaderMenuBurgerMobile';
import BannerNotification from '_core/app/header/BannerNotification';
import { hasRole, logout } from '_core/authentification/service/AuthService';
import { Link } from 'react-router-dom';
import loupe from '_common/component/icon/search.svg';
import clsx from 'clsx';

function goUpThePage() {
    window.scrollTo(0, 0);
}

const AppHeader = ({ user, isTablet, isMobile }) => {
    const [displayMessageChange, setDisplayMessageChange] = useState(false);
    const [displayNotificationChange, setDisplayNotificationChange] = useState(false);
    const [inputMot, setInputMot] = useState('');
    const [isVisibleBannerNotification, setIsVisibleBannerNotification] = useState(false);
    const [isAdherenteActive, setIsAdherenteActive] = useState(false);
    const userEnCoursInscription =
        user && hasRole(['ROLE_ADHERENTE_EN_COURS_INSCRIPTION'], user) && user.etapeInscription !== 'termine';

    useEffect(() => {
        if (!userEnCoursInscription) {
            addMessagerieListener((dt) => {
                setDisplayMessageChange(dt !== undefined && dt !== false);
            });
            addNotificationListener((dateTime) => {
                setDisplayNotificationChange(dateTime !== undefined && dateTime !== false);
            });
        }
    }, []);

    useEffect(() => {
        setIsAdherenteActive(hasRole(['ROLE_ADHERENTE_ACTIVE'], user));
    }, [userEnCoursInscription]);

    const dropdownRef = useRef(null);
    const userMenuRef = useRef(null);
    const id = () => {
        if (user) {
            return user.adherente.id;
        }
    };

    const [isActive, setIsActive] = useState(false);

    const handleNameChange = (e) => {
        setInputMot(e.target.value);
    };

    const onClickSearch = (e) => {
        e.preventDefault();
        if (inputMot.length > 0) {
            let urlParams = new URLSearchParams();
            urlParams.set('page', '1');
            urlParams.set('km', 'plus300');
            urlParams.set('mots', inputMot);
            urlParams.set('competencesId', 'Toutes');
            urlParams.set('activiteId', 'Tous');
            window.location.href = ROUTE_MEMBRES + '?' + urlParams.toString();
        }
    };

    const handleDisconnect = () => {
        logout();
        window.location.href = ROUTE_HOME;
    };

    const handleBurgerClick = () => {
        setIsActive((isActive) => !isActive);
    };

    const handleCloseMenuClick = useCallback(() => {
        setIsActive(false);
    }, []);

    const handleMenuClick = () => {
        setIsActive((isActive) => !isActive);
    };

    const clickOutsideMenuPredict = useCallback(
        (target) => {
            if (!isActive) {
                return false; // car on arrive ici trop tot, on a pas encore ouvert le menu, on va pas le fermer dès maintenant ;-)
            }
            if (dropdownRef.current && dropdownRef.current.contains(target)) {
                return false; // car la fermeture est gérée par un autre handler
            }
            if (userMenuRef.current && userMenuRef.current.contains(target)) {
                return false; // cas nominal, on a cliqué sur le menu, on veut pas le fermer
            }
            return true; // on veut fermer le menu
        },
        [isActive]
    );
    useOnClickOutside(clickOutsideMenuPredict, handleCloseMenuClick);

    return (
        <header
            id='header'
            className={clsx(Style.Navbar, isVisibleBannerNotification ? 'with-banner-notification' : '')}
            role='banner'
        >
            {isAdherenteActive &&
                user &&
                (user.adherente.photo === null ||
                    user.adherente.situation === null ||
                    user.adherente.profession === null ||
                    user.adherente.secteurActivite === null) && (
                    <div className={Style.Bandeau}>
                        <p className={Style.Text}>
                            Pour une meilleure expérience,
                            <Link className={Style.Link} to={resolvePath(ROUTE_PROFIL_EDIT, { id: id() })}>
                                je complète mon profil
                            </Link>
                        </p>
                    </div>
                )}
            {isAdherenteActive && (
                <BannerNotification BannerNotificationVisibilityChanger={setIsVisibleBannerNotification} />
            )}
            <div className={Style.NavbarContainer}>
                <div className={Style.Container}>
                    <div className={Style.NavLogo}>
                        <Link to={isAdherenteActive ? ROUTE_HOME : ROUTE_FIN_ADHESION} className={Style.Logo}>
                            <img src={LogoFDT} className={Style.Logo} alt="Retour à la page d'accueil" />
                        </Link>
                    </div>
                    {/*----------------------------------BARRE DE RECHERCHE----------------------------------*/}
                    {(!isMobile || isTablet) && (
                        <form
                            className={Style.SearchControl}
                            role='search'
                            aria-label='Rechercher une membre'
                            method='post'
                            onSubmit={onClickSearch}
                        >
                            <label className='sr-only' htmlFor='search'>
                                Rechercher une membre
                            </label>
                            <input
                                id='search'
                                className={Style.SearchInput}
                                type='text'
                                placeholder='Rechercher une membre'
                                value={inputMot}
                                onChange={handleNameChange}
                                disabled={!isAdherenteActive}
                            />
                            <input
                                type='image'
                                src={loupe}
                                alt="Lancer la recherche d'une membre"
                                className={Style.SearchIcon}
                                disabled={!isAdherenteActive}
                            />
                        </form>
                    )}
                    {/*----------------------------------FIN BARRE DE RECHERCHE----------------------------------*/}
                    {/*----------------------------------ICONES/LIENS HEADER----------------------------------*/}

                    <div className={Style.MobileIconContainer}>
                        <div className={Style.ItemsContainer}>
                            <div className={Style.Messagerie}>
                                {displayMessageChange && <div className={Style.RedDot}></div>}
                                <Link
                                    to={isAdherenteActive ? ROUTE_MESSAGERIE : '#'}
                                    className={!isAdherenteActive ? Style.IconNavLinkInactive : ''}
                                >
                                    <img src={messagerie} className={Style.ItemIcon} alt='' />
                                    <span className={Style.ItemLabel}>Messagerie</span>
                                </Link>
                            </div>

                            <div className={Style.Notification}>
                                {displayNotificationChange && <div className={Style.RedDotNotifications}></div>}
                                <Link
                                    to={isAdherenteActive ? ROUTE_NOTIFICATIONS : '#'}
                                    className={!isAdherenteActive ? Style.IconNavLinkInactive : ''}
                                >
                                    <img src={notification} className={Style.ItemIcon} alt='' />
                                    <span className={Style.ItemLabel}>Notifications</span>
                                </Link>
                            </div>
                            {isMobile && (
                                <div className={Style.MobileMenuContainer} ref={userMenuRef}>
                                    <button
                                        className={Style.MenuBurger}
                                        onClick={handleBurgerClick}
                                        aria-label='menu utilisatrice'
                                    >
                                        <img src={burger} className={Style.MobileMenuIcon} alt='' />
                                    </button>
                                    {isActive && (
                                        <div className={Style.MobileMenu}>
                                            <button
                                                className={Style.MobileMenuCloseButton}
                                                onClick={handleCloseMenuClick}
                                            >
                                                <img src={closeIcon} alt='close menu' />
                                            </button>
                                            <AppHeaderMenuBurgerMobile
                                                user={user}
                                                isAdherenteActive={isAdherenteActive}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {/*----------------------------------FIN ICONES/LIENS HEADER----------------------------------*/}
                {/*----------------------------------MENU UTILISATRICE----------------------------------*/}
                {user && !isMobile && !isTablet && (
                    <div className={Style.MenuUtilisatrice}>
                        <button className={Style.NameAndPhotoContainer} onClick={handleMenuClick} ref={userMenuRef}>
                            <div className={Style.NameContainer}>
                                <img
                                    className={Style.PortraitUser}
                                    src={
                                        !user.adherente.photo
                                            ? avatar
                                            : process.env.REACT_APP_URL_IMG_PETITE + user.adherente.photo
                                    }
                                    alt=''
                                />
                                <span className={Style.UserNameLabel}>{user.adherente.prenom.toLowerCase()}</span>
                            </div>
                            <img src={fleche} alt='Menu : mes informations' />
                        </button>
                        {isActive && (
                            <div className={Style.UnfoldedUserMenu} ref={dropdownRef} onClick={goUpThePage}>
                                <ul>
                                    <li>
                                        <Link
                                            to={isAdherenteActive ? resolvePath(ROUTE_PROFIL, { id: id() }) : '#'}
                                            className={
                                                isAdherenteActive
                                                    ? Style.UserMenuItem
                                                    : clsx(Style.UserMenuItem, Style.UserMenuItemInactive)
                                            }
                                        >
                                            Mon profil
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={resolvePath(ROUTE_PARAMETRES, { id: id() })}
                                            onClick={goUpThePage}
                                            className={Style.UserMenuItem}
                                        >
                                            Mon compte
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={
                                                isAdherenteActive
                                                    ? resolvePath(ROUTE_AGENDA, { pagenum: 1, futur: true })
                                                    : '#'
                                            }
                                            onClick={goUpThePage}
                                            className={
                                                isAdherenteActive
                                                    ? Style.UserMenuItem
                                                    : clsx(Style.UserMenuItem, Style.UserMenuItemInactive)
                                            }
                                        >
                                            Mon agenda
                                        </Link>
                                    </li>
                                    <li>
                                        <button type='button' className={Style.UserMenuItem} onClick={handleDisconnect}>
                                            Me déconnecter
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                )}
                {/*----------------------------------FIN MENU UTILISATRICE----------------------------------*/}
                <img src={isMobile ? vagueMobile : vague} className={Style.Vague} alt='' />
            </div>
        </header>
    );
};

AppHeader.propTypes = {
    user: PropTypes.shape({
        adherente: PropTypes.shape({
            id: PropTypes.number,
            photo: PropTypes.string,
            prenom: PropTypes.string,
            profession: PropTypes.string,
            situation: PropTypes.shape({
                id: PropTypes.number,
            }),
            secteurActivite: PropTypes.shape({
                id: PropTypes.number,
            }),
        }),
        etapeInscription: PropTypes.string,
    }),
    isTablet: PropTypes.bool,
    isMobile: PropTypes.bool,
};

export default AppHeader;
